import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ImageIntervalBox = ({
  sx,
  intervalTime,
  images,
  children,
  repeat,
  timingFunction = "linear",
  ...props
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % images.length);
    }, intervalTime);
    if (!repeat && index === images.length - 1) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [index, images, intervalTime, repeat]);

  return (
    <Box>
      {images.map((src, imageIndex) => (
        <img
          key={imageIndex}
          src={src}
          alt="background"
          style={{
            opacity: imageIndex <= index ? 1 : 0,
            transition: `opacity ${intervalTime / 1000}s ${timingFunction}`,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            index: 1,
            objectPosition: matches ? "center" : "right",
          }}
        />
      ))}
    </Box>
  );
};
