import React from "react";
import { useSnackbar } from "notistack";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

import { TransitionComponent } from "../components";

import { Blurb } from "./components";
import { targetUrls, snackBarMessage, snackBarVariant } from "../config";

const lottiePorpsProject = {
  width: {
    xs: 96,
    sm: 148,
    md: 192,
    lg: 256,
    xl: 320,
  },
};
const lottieProps = {
  width: {
    xs: 128,
    sm: 164,
    md: 192,
    lg: 256,
    xl: 320,
  },
};
const lottiePropsSmallHeight = {
  width: {
    xs: 128,
    sm: 128,
    md: 128,
    lg: 128,
    xl: 128,
  },
};
const lottiePropsProjectSmallHeight = {
  width: {
    xs: 96,
    sm: 96,
    md: 96,
    lg: 96,
    xl: 96,
  },
};
export function ExhibitionScreenWithoutRiver(props) {
  const matchesHeightLessThan700 = useMediaQuery("(max-height:700px)");

  const [showProject, setShowProject] = React.useState(false);
  const [transition, setTransition] = React.useState(false);
  const [targetUrl, setTargetUrl] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const triggerTransition = (targetUrl) => () => {
    if (!targetUrl) {
      enqueueSnackbar(snackBarMessage, { variant: snackBarVariant });
    } else {
      setTargetUrl(targetUrl);
      setTransition(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F6F6EE",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <TransitionComponent targetUrl={targetUrl} transitionState={transition} />

      <Blurb
        lottieIndex={0}
        buttonText="燕园校区总图"
        buttonType="main"
        onClick={triggerTransition(targetUrls.campusMap)}
        lottieProps={
          matchesHeightLessThan700 ? lottiePropsSmallHeight : lottieProps
        }
      />

      {/* 全部专题 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: matchesHeightLessThan700 ? 0 : 2,
        }}
      >
        <Fade in={showProject} timeout={1500}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              zIndex: 10000,
              gap: {
                xs: 2,
                sm: 4,
                md: 6,
              },
            }}
          >
            <Blurb
              lottieIndex={3}
              buttonText="燕园石刻"
              buttonType="project"
              lottieProps={
                matchesHeightLessThan700
                  ? lottiePropsProjectSmallHeight
                  : lottiePorpsProject
              }
              onClick={triggerTransition(targetUrls.projects.stone)}
              sx={{
                // position: "relative",
                // top: "80%",
                transform: "translateY(80%)",
              }}
            />
            <Blurb
              lottieIndex={5}
              buttonText="革命文物"
              buttonType="project"
              lottieProps={
                matchesHeightLessThan700
                  ? lottiePropsProjectSmallHeight
                  : lottiePorpsProject
              }
              onClick={triggerTransition(targetUrls.projects.revolution)}
            />
            <Blurb
              lottieIndex={4}
              buttonText="文物建筑"
              buttonType="project"
              lottieProps={
                matchesHeightLessThan700
                  ? lottiePropsProjectSmallHeight
                  : lottiePorpsProject
              }
              sx={{
                transform: "translateY(80%)",
              }}
              onClick={triggerTransition(targetUrls.projects.building)}
            />
          </Box>
        </Fade>
        <Blurb
          lottieIndex={1}
          buttonText="专题"
          buttonType="main"
          lottieProps={
            matchesHeightLessThan700 ? lottiePropsSmallHeight : lottieProps
          }
          onClick={() => {
            setShowProject(true);
          }}
        />
        <Fade in={showProject} timeout={1500}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Blurb
              lottieIndex={6}
              buttonText="燕南园历史建筑"
              buttonType="project"
              lottieProps={
                matchesHeightLessThan700
                  ? lottiePropsProjectSmallHeight
                  : lottiePorpsProject
              }
              onClick={triggerTransition(targetUrls.projects.yannanyuan)}
              sx={{
                mr: {
                  xs: 3,
                  sm: 7,
                  md: 12,
                },
              }}
            />
            <Blurb
              lottieIndex={7}
              buttonText="燕东园历史建筑"
              buttonType="project"
              lottieProps={
                matchesHeightLessThan700
                  ? lottiePropsProjectSmallHeight
                  : lottiePorpsProject
              }
              onClick={triggerTransition(targetUrls.projects.yandongyuan)}
            />
          </Box>
        </Fade>
      </Box>

      <Blurb
        lottieIndex={2}
        buttonText="燕园时光机"
        buttonType="main"
        lottieProps={
          matchesHeightLessThan700 ? lottiePropsSmallHeight : lottieProps
        }
        onClick={triggerTransition(targetUrls.timeMachine)}
      />
    </Box>
  );
}
