import React from "react";
import Button from "@mui/material/Button";

export function MainButton({ sx, children, ...props }) {
  return (
    <Button
      variant="contained"
      sx={{
        color: "#F6F6EE",
        backgroundColor: "#799157",
        borderRadius: 100,
        fontSize: {
          xs: 14,
          sm: 16,
          md: 20,
        },
        fontWeight: 600,
        letterSpacing: {
          xs: 2,
          md: 4,
        },
        boxShadow: 12,
        minWidth: {
          xs: 90,
          sm: 100,
        },
        ":hover": {
          boxShadow: 24,
        },
        transition: "box-shadow 0.3s",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
