import React from "react";
import Lottie from "react-lottie";
import Tooltip from "@mui/material/Tooltip";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { lottieAnimations } from "../../config";
import { Box } from "@mui/material";

export function LottieIcon({ lottieIndex, width, tooltipText, onClick }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  let lottieWidth;
  //width 为固定数字
  if (typeof width === "number") {
    lottieWidth = width;
  }
  if (typeof width === "object") {
    const widthMap = {
      xs: isXs ? width.xs : 0,
      sm: isSm ? width.sm : 0,
      md: isMd ? width.md : 0,
      lg: isLg ? width.lg : 0,
      xl: isXl ? width.xl : 0,
    };

    lottieWidth =
      widthMap.xs || widthMap.sm || widthMap.md || widthMap.lg || widthMap.xl;
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimations[lottieIndex],
  };

  return tooltipText ? (
    <Tooltip title={tooltipText} placement="bottom">
      <Box onClick={onClick}>
        <Lottie options={defaultOptions} width={lottieWidth} />
      </Box>
    </Tooltip>
  ) : (
    <Box onClick={onClick}>
      <Lottie options={defaultOptions} width={lottieWidth} />
    </Box>
  );
}
