import bg01 from "./assets/bg/bg01.jpg";
import bg02 from "./assets/bg/bg02.jpg";
import bg03 from "./assets/bg/bg03.jpg";
import bg04 from "./assets/bg/bg04.jpg";
import bg05 from "./assets/bg/bg05.jpg";
// import exhibitionBg from "./assets/exhibition_bg.png";
import river01 from "./assets/river/r1.png";
import river02 from "./assets/river/r2.png";
import river03 from "./assets/river/r3.png";
import river04 from "./assets/river/r4.png";
import river05 from "./assets/river/r5.png";

import * as animation01 from "./assets/lottie/01.json";
import * as animation02 from "./assets/lottie/02.json";
import * as animation03 from "./assets/lottie/03.json";
import * as animation04 from "./assets/lottie/04.json";
import * as animation05 from "./assets/lottie/05.json";
import * as animation06 from "./assets/lottie/06.json";
import * as animation07 from "./assets/lottie/07.json";
import * as animation08 from "./assets/lottie/08.json";

//背景图片切换预加载与图片切换时间及函数配置
function preloadImages(urls) {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}
export const images = [bg01, bg02, bg03, bg04, bg05];
preloadImages(images);
export const imageChangeInterval = 2000;
export const timingFunction = "ease-in-out";

//各类数字图纸或SourceStory跳转链接
export const targetUrls = {
  campusMap: "http://pku-viewer.yuancj.com/drawing/cloe259vz01mywlewjxust27d",
  projects: {
    stone: "",
    building: "",
    revolution: "",
    yannanyuan: "",
    yandongyuan: "",
  },
  timeMachine: "",
};
export const snackBarMessage = "专题展览制作中,敬请期待...";
export const snackBarVariant = "warning";
//触屏滑动多少距离才触发翻页
export const minTouchScrollMove = 50;

//开屏特效持续时间，配合TransitionComponent使用
export const transitionTime = 2000;

export const lottieAnimations = [
  animation01,
  animation02,
  animation03,
  animation04,
  animation05,
  animation06,
  animation07,
  animation08,
];
export const riverImgs = [
  [river01, river02, undefined],
  [undefined, river03, undefined],
  [undefined, river04, river05],
];
