import React from "react";
import Box from "@mui/material/Box";
import { MainButton } from "./MainButton";
import { LottieIcon } from "./LottieIcon";
import { ProjectButton } from "./ProjectButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import zIndex from "@mui/material/styles/zIndex";

export function Blurb({
  lottieIndex,
  buttonText,
  buttonType,
  onClick,
  sx,
  lottieProps,
  hideTitle,
  ...props
}) {
  const matchesHeightLessThan700 = useMediaQuery("(max-height:700px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        //hover 后 缩放大小，但是保留原本的sx.translate
        // pointerEvents: "none",
        "&:hover": {
          transform:
            sx && sx.transform ? `${sx.transform} scale(1.05)` : "scale(1.05)",
        },
        transition: "transform 0.3s",

        ...sx,
      }}
      {...props}
    >
      <LottieIcon
        onClick={onClick}
        tooltipText={buttonType ? "" : buttonText}
        lottieIndex={lottieIndex}
        {...lottieProps}
      />

      {buttonType === "main" && (
        <MainButton
          sx={{
            py: matchesHeightLessThan700 ? "2px" : "6px",
            pointerEvents: "auto",
          }}
          onClick={onClick}
        >
          {buttonText}
        </MainButton>
      )}
      {buttonType === "project" && (
        <ProjectButton
          sx={{
            mt: matchesHeightLessThan700 ? "1px" : 1,
            py: matchesHeightLessThan700 ? "1px" : "3px",
          }}
          onClick={onClick}
        >
          {buttonText}
        </ProjectButton>
      )}
    </Box>
  );
}
