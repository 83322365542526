import React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import logo from "../assets/logo.png";
import sourcescene from "../assets/sourcescene.png";

export function MenuBar(props) {
  return (
    <AppBar
      position="fixed"
      sx={{
        height: {
          xs: 115,
          sm: 70,
          md: 80,
          lg: 90,
          xl: 100,
        },
        position: "absolute",
        top: 0,
      }}
      color="secondary"
      enableColorOnDark
    >
      <Toolbar
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          justifyContent: {
            xs: "space-around",
            sm: "space-between",
          },
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: {
              xs: 120,
              sm: 130,
              md: 140,
              lg: 150,
              xl: 160,
            },
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "100%",
              display: "block",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: 100,
                sm: 110,
                md: 120,
                lg: 130,
                xl: 140,
              },
            }}
          >
            <img
              src={sourcescene}
              alt="sourcescene"
              style={{
                width: "100%",
                display: "block",
                objectFit: "contain",
                transform: "scale(0.9)",
              }}
            />
          </Box>
          <Box
            maxHeight={50}
            sx={{
              borderLeft: "1px solid",
              ml: {
                xs: 0,
                sm: 1,
              },
              pl: {
                xs: 1,
                sm: 2,
              },
              fontSize: {
                xs: 12,
                sm: 13,
                md: 14,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "inherit",
              }}
            >
              北京大学考古文博学院
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: {
                  xs: 10,
                  md: 11,
                },
              }}
            >
              张剑葳课题组 制作
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
