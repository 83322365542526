import React from "react";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

import { TransitionComponent } from "../components";

import { Blurb } from "./components";
import {
  targetUrls,
  riverImgs,
  snackBarMessage,
  snackBarVariant,
} from "../config";

const lottieProps = {
  width: {
    xs: 128,
    sm: 128,
    md: 192,
    lg: 256,
    xl: 320,
  },
};

//a box that located at the given position of a 3x3 grid
function PositionBox({ x, y, w = 1, sx, children, ...props }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: `${y * 33.33}%`,
        left: `${x * 33.33}%`,
        width: `${w * 33.33}%`,
        height: "33.33vh",
        backgroundImage: riverImgs[x][y] ? `url(${riverImgs[x][y]})` : "none",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // border: "1px solid black",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function ExhibitionScreenWithRiver(props) {
  const [showProject, setShowProject] = React.useState(false);
  const [transition, setTransition] = React.useState(false);
  const [targetUrl, setTargetUrl] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const triggerTransition = (targetUrl) => () => {
    if (!targetUrl) {
      enqueueSnackbar(snackBarMessage, { variant: snackBarVariant });
    } else {
      setTargetUrl(targetUrl);
      setTransition(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F6F6EE",
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <TransitionComponent targetUrl={targetUrl} transitionState={transition} />

      <PositionBox x={0} y={0}>
        <Blurb
          lottieIndex={0}
          buttonText="燕园校区总图"
          buttonType="main"
          onClick={triggerTransition(targetUrls.campusMap)}
          lottieProps={lottieProps}
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        />
      </PositionBox>

      {/* 文物专题 */}
      <PositionBox x={1} y={0} w={2}>
        <Fade in={showProject} timeout={1500}>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              gap: {
                xs: 0,
                md: 1,
                xl: 2,
              },
              justifyContent: "center",
            }}
          >
            <Blurb
              lottieIndex={3}
              buttonText="燕园石刻"
              buttonType="project"
              lottieProps={lottieProps}
              onClick={triggerTransition(targetUrls.projects.stone)}
            />
            <Blurb
              lottieIndex={4}
              buttonText="文物建筑"
              buttonType="project"
              lottieProps={lottieProps}
              onClick={triggerTransition(targetUrls.projects.building)}
            />
            <Blurb
              lottieIndex={5}
              buttonText="革命文物"
              buttonType="project"
              lottieProps={lottieProps}
              onClick={triggerTransition(targetUrls.projects.revolution)}
            />
          </Box>
        </Fade>
      </PositionBox>

      {/* 河流背景无内容 */}
      <PositionBox x={0} y={1}></PositionBox>

      <PositionBox x={1} y={1}>
        <Blurb
          lottieIndex={1}
          buttonText="专题"
          buttonType="main"
          lottieProps={lottieProps}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          onClick={() => {
            setShowProject(true);
          }}
        />
      </PositionBox>

      {/* 河流背景无内容 */}
      <PositionBox x={2} y={1}></PositionBox>

      {/* 园区专题 */}
      <PositionBox x={0} y={2} w={2}>
        <Fade in={showProject} timeout={1500}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              display: "flex",

              justifyContent: "center",
              gap: {
                xs: 0,
                md: 5,
                xl: 10,
              },
            }}
          >
            <Blurb
              lottieIndex={6}
              buttonText="燕南园历史建筑"
              buttonType="project"
              lottieProps={lottieProps}
              onClick={triggerTransition(targetUrls.projects.yannanyuan)}
            />
            <Blurb
              lottieIndex={7}
              buttonText="燕东园历史建筑"
              buttonType="project"
              lottieProps={lottieProps}
              onClick={triggerTransition(targetUrls.projects.yandongyuan)}
            />
          </Box>
        </Fade>
      </PositionBox>

      <PositionBox x={2} y={2}>
        <Blurb
          lottieIndex={2}
          buttonText="燕园时光机"
          buttonType="main"
          lottieProps={lottieProps}
          onClick={triggerTransition(targetUrls.timeMachine)}
        />
      </PositionBox>
    </Box>
  );
}
