import React from "react";
import Box from "@mui/material/Box";

import { ScreenContext } from "../context";

export function ScreenScrollContainer({ children, minTouchScrollMove = 50 }) {
  const containerRef = React.useRef(null);
  const [currentScreen, setCurrentScreen] = React.useState(0);
  const [touchStartY, setTouchStartY] = React.useState(0);

  React.useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
      if (event.deltaY > 0 && currentScreen === 0) {
        setCurrentScreen(1);
      } else if (event.deltaY < 0 && currentScreen === 1) {
        setCurrentScreen(0);
      }
    };

    const handleTouchStart = (event) => {
      setTouchStartY(event.touches[0].clientY);
    };

    const handleTouchEnd = (event) => {
      const touchEndY = event.changedTouches[0].clientY;
      if (touchStartY - touchEndY > minTouchScrollMove && currentScreen === 0) {
        setCurrentScreen(1);
      } else if (
        touchEndY - touchStartY > minTouchScrollMove &&
        currentScreen === 1
      ) {
        setCurrentScreen(0);
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("touchstart", handleTouchStart, { passive: false });
    window.addEventListener("touchend", handleTouchEnd, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [currentScreen, touchStartY, minTouchScrollMove]);

  return (
    <Box
      ref={containerRef}
      sx={{
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          position: "relative",
          transform: `translateY(-${currentScreen * 100}vh)`,
          transition: "transform 0.8s ease-in-out",
        }}
      >
        <ScreenContext.Provider
          value={{
            currentScreen,
            setCurrentScreen,
          }}
        >
          {children}
        </ScreenContext.Provider>
      </Box>
    </Box>
  );
}
