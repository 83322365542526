import React from "react";
import { WelcomeScreen } from "./welcomeScreen";
import { ExhibitionScreen } from "./exhibitionScreen";
import { ScreenScrollContainer } from "./components";
import { minTouchScrollMove } from "./config";
function App() {
  // Prevent scrolling on touch devices
  React.useEffect(() => {
    document.body.addEventListener(
      "touchmove",
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );
  }, []);
  return (
    <ScreenScrollContainer minTouchScrollMove={minTouchScrollMove}>
      <WelcomeScreen></WelcomeScreen>
      <ExhibitionScreen></ExhibitionScreen>
    </ScreenScrollContainer>
  );
}

export default App;
