import React from "react";
import Box from "@mui/material/Box";

import { MenuBar, TransitionComponent } from "../components";
import { Content, ImageIntervalBox } from "./components";

import {
  images,
  imageChangeInterval,
  timingFunction,
  targetUrl,
  transitionTime,
} from "../config";

export function WelcomeScreen() {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
      }}
    >
      <MenuBar></MenuBar>
      <ImageIntervalBox
        intervalTime={imageChangeInterval}
        images={images}
        timingFunction={timingFunction}
        // repeat
      ></ImageIntervalBox>
      <Content></Content>
    </Box>
  );
}
