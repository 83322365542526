import React from "react";
import Box from "@mui/material/Box";

import { transitionTime } from "../config";

export const TransitionComponent = ({ transitionState, targetUrl }) => {
  const [isTransitioning, setIsTransitioning] = React.useState(false);

  React.useEffect(() => {
    if (transitionState) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setIsTransitioning(false);
        if (targetUrl) {
          window.open(targetUrl, "_self");
        }
      }, transitionTime + 300);

      return () => clearTimeout(timer);
    }
  }, [transitionState, targetUrl]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        transform: isTransitioning ? "scaleX(1.01)" : "scaleX(0)",
        transformOrigin: "center",
        transition: `transform ${transitionTime / 1000}s ease-in-out`,
        zIndex: 9999, // 确保在最上层
      }}
    />
  );
};
