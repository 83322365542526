import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#BC5127",
    },
    secondary: {
      main: "rgba(1,1,1,0.5)",
    },
  },
  typography: {
    fontFamily:
      '"Roboto", "Helvetica","Microsoft YaHei", "SimSun", "SimHei", "FangSong", "KaiTi", sans-serif',
  },
});
