import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { TransitionComponent } from "../components";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Blurb } from "./components";
import { SourceDrawingsUrl, riverImgs } from "../config";
import { ExhibitionScreenWithRiver } from "./ExhibitionScreenWithRiver";
import { ExhibitionScreenWithoutRiver } from "./ExhibitionScreenWithoutRiver";

//a box that located at the given position of a 3x3 grid

export function ExhibitionScreen(props) {
  const matches = useMediaQuery("(min-width:900px)");
  //aspect ratio landscape
  const isLandscape = useMediaQuery("(min-aspect-ratio: 1/1)");

  return matches && isLandscape ? (
    <ExhibitionScreenWithRiver {...props} />
  ) : (
    <ExhibitionScreenWithoutRiver {...props} />
  );

  // return <ExhibitionScreenWithRiver {...props} />;
}
