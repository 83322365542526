import React from "react";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

export function ProjectButton({ sx, children, ...props }) {
  const matchesHeightLessThan660 = useMediaQuery("(max-height:660px)");

  return (
    <Button
      variant="contained"
      sx={{
        color: "#42210B",
        backgroundColor: "#F6F6EE",
        borderRadius: 100,
        border: "2px solid #000000",
        fontSize: {
          xs: 12,
          sm: 14,
          md: 18,
        },
        fontWeight: 600,
        letterSpacing: {
          xs: 1,
          md: 4,
        },
        boxShadow: 8,
        ":hover": {
          boxShadow: 16,
          backgroundColor: "#42210B",
          color: "#F6F6EE",
        },
        transition: "box-shadow 0.3s",

        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
}
