import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import title from "../../assets/title.png";
import { useScreenContext } from "../../context";

export function Content() {
  const { setCurrentScreen } = useScreenContext();
  return (
    <Box
      sx={{
        bottom: 0,
        mb: 9,
        left: 0,
        zIndex: 2,
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "80%",
            sm: "60%",
            md: "50%",
            lg: "40%",
            xl: "35%",
          },
          mb: {
            xs: 3,
            md: 5,
          },
          maxWidth: 640,
          minHeight: {
            xs: 100,
            sm: 120,
            md: 140,
            lg: 160,
            xl: 180,
          },
          backgroundImage: `url(${title})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: 2,
        }}
      />
      <Button
        variant="contained"
        sx={{
          borderRadius: 100,

          width: {
            xs: 150,
            sm: 180,
            md: 200,
            lg: 220,
          },
          height: {
            xs: 40,
            sm: 45,
            md: 55,
          },
          fontSize: {
            xs: 16,
            sm: 18,
            md: 20,
          },
          fontWeight: 700,
          letterSpacing: {
            xs: 2,
            sm: 3,
            md: 4,
          },
        }}
        onClick={() => {
          setCurrentScreen(1);
        }}
      >
        查看展览
      </Button>
    </Box>
  );
}
